import React from "react"

import Container from "../../../components/Container/Container"
import Title from "../../../components/Title/Title"
import Button from "../../../components/Button/Button"
import PageCover from "../../../components/PageCover/PageCover"

const Hero = ({ roomData }) => {
  return (
    <>
      <div>
        <PageCover
          imageLocalFile={roomData.cover_image}
          alt={roomData.room_page_title.text}
        />
      </div>
      <Container>
        <div className="flex flex-col items-center text-center">
          <div>
            <Title preTitle>ACCOMODATIONS</Title>
          </div>

          <div className="sm:mr-[-16px]">
            <Title singlePage>{roomData.room_page_title.text}</Title>
          </div>

          <div className="pt-3 sm:pt-7 max-w-[580px]">
            <div dangerouslySetInnerHTML={{ __html: roomData.intro.html }} />
          </div>
          <div className="pt-12">
            <Button arrowRight linkTo="/booking">
              Reserve Now
            </Button>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Hero
