import React, { useState, useRef, useEffect } from "react"
import { useKeenSlider } from "keen-slider/react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import * as styles from "./Slider.module.css"
import Container from "../../../components/Container/Container"

const Slider = ({ slideshowItems }) => {
  // Slider is 100% ready or not
  const [sliderReady, setSliderReady] = useState(false)
  const [pause, setPause] = React.useState(false)
  const timer = useRef()
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    duration: 1000,
    loop: true,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },

    created() {
      // Slider is 100% ready to work
      setSliderReady(true)
    },
    dragStart: () => {
      setPause(true)
    },
    dragEnd: () => {
      setPause(false)
    },
  })

  // Autoplay timer
  const handleTimer = () => {
    if (timer.current) {
      clearInterval(timer.current)
    }

    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, 10000)
  }

  // Activate slider autoplay
  useEffect(() => {
    handleTimer()

    return () => {
      if (timer.current) {
        clearInterval(timer.current)
      }
    }
  }, [pause, slider])

  return (
    <>
      <div>
        <div className={styles.navigationWrapper}>
          <div
            ref={sliderRef}
            className={`keen-slider h-[300px] sm:h-[400px] md:h-[500px] lg:h-[700px]  ${
              sliderReady ? "" : "block"
            }`}
          >
            {slideshowItems.map(({ slide_image }, index) => {
              const roomSlideImage = getImage(slide_image)

              return (
                <div className="max-h-full keen-slider__slide " key={index}>
                  <GatsbyImage
                    image={roomSlideImage}
                    alt=""
                    className="object-cover h-full"
                  />
                </div>
              )
            })}
          </div>

          {slider && (
            <>
              <ArrowLeft
                onClick={e => {
                  e.stopPropagation() || slider.prev()
                }}
                // Stop disabled arrow because it is infinite looped slider
                // disabled={currentSlide === 0}
              />
              <ArrowRight
                onClick={e => {
                  e.stopPropagation() || slider.next()
                }}
                // Stop disabled arrow because it is infinite looped slider
                // disabled={currentSlide === slider.details().size - 1}
              />
            </>
          )}

          {slider && (
            <div
              className={`absolute z-40 bottom-3 md:bottom-6 lg:bottom-9 left-1/2 transform -translate-x-1/2 ${styles.dots}`}
            >
              {[...Array(slider.details().size).keys()].map(idx => {
                return (
                  <button
                    key={idx}
                    onClick={() => {
                      slider.moveToSlideRelative(idx)
                    }}
                    className={`${styles.dot} ${
                      currentSlide === idx ? "!bg-primary" : "bg-heading"
                    }`}
                  >
                    {""}
                  </button>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Slider

function ArrowLeft(props) {
  const disabled = props.disabled ? styles.arrowDisabled : ""
  return (
    <svg
      onClick={props.onClick}
      className={`lg:!left-7 text-heading ${styles.arrow} ${styles.arrowLeft} ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
    </svg>
  )
}

function ArrowRight(props) {
  const disabled = props.disabled ? styles.arrowDisabled : ""
  return (
    <svg
      onClick={props.onClick}
      className={`lg:!right-7 text-heading ${styles.arrow} ${styles.arrowRight} ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </svg>
  )
}
