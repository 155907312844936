import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Container from "../../../components/Container/Container"
import Title from "../../../components/Title/Title"
import Button from "../../../components/Button/Button"

const Amenities = ({ amenities, amenities_title, amenities_text }) => {
  const amenitiesItems = amenities
  return (
    <>
      <Container className="!pb-0">
        <div className="flex flex-col md:flex-row">
          <div className="flex-auto w-full md:w-1/2">
            {amenitiesItems && amenitiesItems.length > 0 && (
              <div>
                <Title colorClass="text-secondary">
                  {amenities_title.text}
                </Title>
                <div className="pt-3 md:pt-5 max-w-[635px]">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: amenities_text.html,
                    }}
                  />
                </div>
                <div className="pt-6">
                  <div className="flex flex-wrap">
                    {amenitiesItems.map(
                      ({ icon_image, amenity_title }, index) => {
                        const iconImage = getImage(icon_image)
                        return (
                          <div
                            key={index}
                            className="flex flex-col items-center flex-auto w-full px-3 my-5 text-center sm:w-1/2 lg:w-1/5"
                          >
                            <GatsbyImage
                              image={iconImage}
                              alt={amenity_title.text}
                              className="opacity-70"
                            />
                            <div className="pt-1">{amenity_title.text}</div>
                          </div>
                        )
                      }
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="bg-[#ebebeb] bg-opacity-90 py-[15px] flex justify-center mt-8 mb-5">
              <Button arrowRight linkTo="/booking">
                SEE AVAILABILITY
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Amenities
