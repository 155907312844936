import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/SEO/SEO"

import Hero from "../sections/room/Hero/Hero"
import Slider from "../sections/room/Slider/Slider"
import Amenities from "../sections/room/Amenities/Amenities"
import Reviews from "../sections/common/Reviews/Reviews"

const Room = ({ data }) => {
  if (!data) return null

  const roomData = data.prismicRoom.data

  return (
    <>
      <SEO
        title={roomData.meta_title.text}
        description={roomData.meta_description.text}
      />

      <section>
        <Hero roomData={roomData} />
      </section>

      <section>
        <Slider slideshowItems={roomData.slideshow} />
      </section>
      <section>
        <Amenities
          amenities_title={roomData.amenities_title}
          amenities_text={roomData.amenities_text}
          amenities={roomData.amenities}
        />
      </section>
      <section className="pb-5 md:pb-7 lg:pb-10">
        <Reviews />
      </section>
    </>
  )
}
export default Room

export const query = graphql`
  query ($slug: String!) {
    prismicRoom(uid: { eq: $slug }) {
      uid
      data {
        room_page_title {
          text
        }
        intro {
          html
        }
        cover_image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }

        slideshow {
          slide_image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }

        amenities_title {
          text
        }
        amenities_text {
          html
        }

        amenities {
          icon_image {
            gatsbyImageData(width: 48, layout: FIXED)
          }
          amenity_title {
            text
          }
        }

        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }
  }
`
